<template>
	<div id="supportUsContainer" class="mainSupportUsContainer">
		<section v-show="showingVideo">
			<video
				muted
				id="endVideo"
				@ended="videoCompleted"
			>
			</video>
		</section>
	</div>
</template>

<script>
import constants from '../constants';
import '../css/support.css';

export default {
	name: 'SupportUs',
	props: {
		endVideo: {
			type: String,
		},
		playVideo: {
			type: Boolean,
		},
	},
	data() {
		return {
			showingVideo: false,
			timeoutSupportCompleted: null,
		};
	},
	watch: {
		playVideo() {
			const video = document.getElementById('endVideo');
			if (video && this.playVideo) {
				this.showingVideo = true;
				video.src = this.endVideo;
				video.click();
				video?.play();
				video.muted = false;
			}
		},
	},
	methods: {
		videoCompleted() {
			this.setCompletedState();
		},
		setCompletedState() {
			const timeout = 30000;

			this.timeoutSupportCompleted = setTimeout(() => {
				clearTimeout(this.timeoutSupportCompleted);

				this.$store.dispatch(constants.SUPPORT_COMPLETED);
			}, timeout);
		},
	},
};

</script>
