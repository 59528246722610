/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import constants from '../constants';
import CommManager from '../api/CommManager';

Vue.use(Vuex);

const isIOS = ((navigator.userAgent.match(/(iPhone|iPad|iPod|Mac)/i)) !== null);
const commManager = new CommManager();

export default new Vuex.Store({
	state: {
		currentRaffleData: {
			gameid: 0,
			drawnumber: 0,
			drawdate: 0,
			latestdrawenddate: 0,
		},
		enableSounds: false,
		initializedApp: false,
		isIOS,
		liveRaffle: {
			show: false,
			data: {},
		},
		preRaffle: {
			show: false,
			data: {},
		},
		waitingFor: true,
		supportUs: {
			show: false,
			data: null,
		},
		error: {
			liveDraw: null,
		},
	},
	getters: {
		liveRaffle(state) {
			return state.liveRaffle;
		},
		waitingFor(state) {
			return state.waitingFor;
		},
		preRaffle(state) {
			return state.preRaffle;
		},
		supportUs(state) {
			return state.supportUs;
		},
		getErrorLiveDraw(state) {
			return !state.error.liveDraw;
		},
		getErrors(state) {
			return !state.error.liveDraw;
		},
		statusApp(state) {
			return (state.showDraw);
		},
		getStatusSounds(state) {
			return state.enableSounds;
		},
		isIOS(state) {
			return state.isIOS;
		},
	},
	mutations: {
		setInitializedApp(state, param) {
			state.initializedApp = param;
		},
		setStartRaffle(state, response) {
			state.currentRaffleData = response.currentRaffleData;
			state.liveRaffle = {
				show: false,
				data: response.raffleData,
			};
			state.preRaffle = {
				show: true,
				data: response.preRaffleData,
			};
			state.waitingFor = false;
		},
		setLiveRaffle(state, param) {
			state.liveRaffle.show = param;
		},
		setPreRaffle(state) {
			state.preRaffle.show = false;
		},
		setWaitingFor(state, visible) {
			state.waitingFor = visible;
		},
		setSupportUs(state, param) {
			state.supportUs.show = param;
		},
		setStatusSounds(state, param) {
			state.enableSounds = param;
		},
		clearErrorsInData(state) {
			state.errorMessage = '';
		},
		setErrorLive(state, param) {
			state.error.liveDraw = param;
			state.waitingFor = true;
		},
	},
	actions: {
		initializeApp(context) {
			commManager.initialize(this);

			context.commit(constants.SET_INITIALIZED_APP, true);
		},
		preRaffleCompleted(context) {
			context.commit('setPreRaffle');
			context.commit('setLiveRaffle', true);
		},
		liveRaffleCompleted(context) {
			context.commit('setSupportUs', true);
			context.commit('setLiveRaffle', false);
		},
		supportCompleted(context) {
			context.commit('setWaitingFor', true);
			context.commit('setSupportUs', false);
			context.commit('setLiveRaffle', false);

			commManager.dispatchRequestLiveRaffle(false);
		},
		statusChangeSounds(context, param) {
			context.commit('setStatusSounds', param);
		},
	},
});
