<template>
	<div class="mainIdleContainer">
		<div class="idleContainer" >
			<div class="
				idleText
				colorText_blue
				animate__animated
				animate__fadeIn"
			>
				<div class="readyText">
					¡Prepárate! En breve <br />comenzamos con el sorteo.
				</div>
				<div class="
					idleLogoGranDia
					animate__animated
					animate__headShake
					animate__infinite
					animate__slower
					animate__delay-5s
					"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import '../css/idle.css';

export default {
	name: 'IdleApp',
};

</script>
