<template>
	<div class="mainPreRafflingContainer">
		<div class="preRafflingContainer">
			<section v-show="showingVideo">
				<video id="welcomeVideo" muted @ended="videoCompleted"></video>
			</section>
		</div>
	</div>
</template>

<script>
import constants from '../constants';
import '../css/preRaffling.css';

export default {
	name: 'PreRaffling',
	props: {
		data: {
			type: Object,
		},
		welcomeVideo: {
			type: String,
		},
	},
	data() {
		return {
			showingVideo: false,
			date: '',
			time: '',
			gameId: '',
			video: '',
		};
	},
	beforeMount() {
		this.gameId = this.data?.raffleNumber || 0;
		this.date = this.data?.date;
		this.time = this.data?.time || '';
	},
	mounted() {
		this.showWelcomeVideo();
	},
	methods: {
		showWelcomeVideo() {
			const video = document.getElementById('welcomeVideo');

			if (video) {
				video.src = this.welcomeVideo;
				this.showingVideo = true;
				video.click();
				video?.play();
				video.muted = false;
			}
		},
		videoCompleted() {
			this.setCompletedState();
		},
		setCompletedState() {
			this.$store.dispatch(constants.PRE_RAFFLE_COMPLETED);
		},
	},
};

</script>
