<template>
	<div id="mainContainer" class="w100 h100">
		<IdleApp v-if="getWaitingFor" />
		<PreRaffling
			v-if="getPreRaffle.show"
			:data="getPreRaffle.data"
			:welcomeVideo="getWelcomeVideo"
		/>
		<RafflingView
			v-if="getLiveRaffle.show"
			:data="getLiveRaffle.data"
		/>
	</div>
</template>

<script>
import IdleApp from './components/Idle.vue';
import PreRaffling from './components/PreRafling.vue';
import RafflingView from './components/Raffling.vue';
import constants from './constants';
import './css/animate.min.css';

const welcome = require('./assets/video/welcome.webm');

export default {
	name: 'App',
	components: {
		IdleApp,
		PreRaffling,
		RafflingView,
	},
	data() {
		return {
			showIdle: true,
			showPreRaffling: false,
			showRaffling: false,
		};
	},
	mounted() {
		const mainContainer = document.getElementById('mainContainer');
		if (mainContainer) {
			mainContainer.click();
		}

		this.initializeApp();
	},
	computed: {
		getLiveRaffle() {
			return this.$store.getters.liveRaffle;
		},
		getPreRaffle() {
			return this.$store.getters.preRaffle;
		},
		getSupportUs() {
			return this.$store.getters.supportUs;
		},
		getWaitingFor() {
			return this.$store.getters.waitingFor;
		},
		getWelcomeVideo() {
			return welcome;
		},
		getIsIOS() {
			return this.$store.getters.isIOS;
		},
	},
	methods: {
		initializeApp() {
			this.$store.dispatch(constants.INITIALIZE_APP);
		},
	},
};
</script>
