const constants = {
	INITIALIZE_APP: 'initializeApp',
	SET_INITIALIZED_APP: 'setInitializedApp',
	RAFFLE_COMPLETED: 'liveRaffleCompleted',
	PRE_RAFFLE_COMPLETED: 'preRaffleCompleted',
	SUPPORT_COMPLETED: 'supportCompleted',
	IDLE: 'wait',
};

export default constants;
