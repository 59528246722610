<template>
	<div class="mainRafflingContainer">
		<div class="rafflingContainer animate__animated animate__fadeIn">
			<div class="logo" />
			<div
				v-show="showResult"
				class="
					showResult
					rafflingText
					colorText_blue
					animate__animated
					animate__delay-2s
					animate__faster
					animate__fadeIn
				"
			>
				<div class="titleText showTextResult">
					El número<br />ganador es:
				</div>
				<div class="textDateResult">{{date}}</div>
			</div>
			<div class="reelsContainer">
				<div id="pointerLeft" class="pointer pointerLeft" />
				<div
					id="reelLeft"
					class="rafflingText reel colorText_white"
				>
					<div
						v-for="index of totalNumber"
						:key="`l-number-${index - 1}`"
						:id="`l-number-${index - 1}`"
						:class="`numberReel numberLeft l-number-${index - 1}`"
					>
						{{index - 1}}
					</div>
					<div
						v-for="index of totalNumberResult"
						:key="`l-result-pos-${index - 1}`"
						:id="`l-result-pos-${index - 1}`"
						class="numberReel numberLeft numberResult"
					>
						0
					</div>
				</div>
				<div
					id="reelRight"
					class="rafflingText reel colorText_white"
				>
					<div
						v-for="index of totalNumber"
						:key="`r-number-${totalNumber - index}`"
						:id="`r-number-${totalNumber - index}`"
						class="numberReel numberRight"
						:class="`numberReel numberRight r-number-${totalNumber - index}`"
					>
						{{totalNumber - index}}
					</div>
					<div
						v-for="index of totalNumberResult"
						:key="`r-result-pos-${totalNumberResult - index}`"
						:id="`r-result-pos-${totalNumberResult - index}`"
						class="numberReel numberRight numberResult"
					>
						0
					</div>
				</div>
				<div id="pointerRight" class="pointer pointerRight" />
				<div class="certifiedDraw colorText_white">
					<span class="textCertified">Sorteo certificado por el notario público Donato Carpio</span>
					<div class="notaryLogo" />
				</div>
				<div class="DevelopBy colorText_white">
					<span class="textCertified">Desarrollado por Vibra Gaming, proveedor certificado GLI</span>
				</div>
			</div>
		</div>
		<SupportUs
			v-show="showSupportUs"
			:endVideo="getEndVideo"
			:playVideo="showSupportUs"
		/>
	</div>
</template>

<script>
import {
	gsap,
	Back,
	Sine,
} from 'gsap';
import SupportUs from './support.vue';
import '../css/raffling.css';

const reelsSpin = require('../assets/sounds/reels_spin2.mp3');
const stopReel = require('../assets/sounds/stop_reel.mp3');
const preWin = require('../assets/sounds/pre-win.mp3');
const winAudio = require('../assets/sounds/win.mp3');
const audioRaffling = require('../assets/sounds/audioRaffling.mp3');
const end = require('../assets/video/end.webm');

export default {
	name: 'RafflingView',
	props: {
		data: {
			type: Object,
		},
	},
	components: {
		SupportUs,
	},
	data() {
		return {
			activeAnimations: [],
			activeLeftLoopEnd: false,
			activeLoop: true,
			activeMiddleLoop: false,
			activeRightLoopEnd: false,
			backgroundSound: null,
			currentNumber: 0,
			date: '',
			delayLoop: 0.15, // seconds
			leftLoopFinished: false,
			preWinSound: null,
			result: '',
			rightLoopFinished: false,
			showResult: false,
			showSupportUs: false,
			speedLoop: 0.9, // seconds
			spinSound: null,
			stopSound: null,
			timeoutFinishLoop: null,
			timeoutMiddleLoop: null,
			timeoutShowResult: null,
			timeoutStartSecuence: null,
			totalNumber: 10,
			totalNumberResult: 5,
			winSound: null,
			reelToShow: [
				{	pos: '120%' },
				{ pos: '70%' },
				{ pos: '30%' },
				{ pos: '-15%' },
				{ pos: '-120%' },
			],
		};
	},
	beforeMount() {
		this.result = this.data.result;
		this.date = `${this.data?.date} - ${this.data?.time}`;
		this.createSounds();
	},
	mounted() {
		this.playSpinSound();
		this.loopAnimation();
		this.setActiveMiddleLoop();
	},
	computed: {
		getEndVideo() {
			return end;
		},
	},
	watch: {
		showResult() {
			this.timeoutShowResult = setTimeout(() => {
				clearTimeout(this.timeoutShowResult);
				this.timeoutShowResult = null;

				this.visibleBackgroundReels(false);
				this.setShowSupportUs();
			}, 1000);
		},
	},
	methods: {
		createSounds() {
			const backgroundAudio = new Audio(audioRaffling);
			this.backgroundSound = backgroundAudio;

			const spinAudio = new Audio(reelsSpin);
			this.spinSound = spinAudio;
			this.spinSound.loop = true;
			this.spinSound.volume = 0.3;

			const stopReelAudio = new Audio(stopReel);
			this.stopSound = stopReelAudio;
			this.stopSound.volume = 1;

			const audioPreWin = new Audio(preWin);
			this.preWinSound = audioPreWin;
			this.preWinSound.volume = 1;

			const audioWin = new Audio(winAudio);
			this.winSound = audioWin;
			this.winSound.volume = 0.5;
		},
		playSpinSound() {
			if (this.spinSound && this.backgroundSound) {
				this.backgroundSound.play();
				this.spinSound.play();
			}
		},
		loopAnimation() {
			if (!this.activeLoop) {
				return;
			}

			let number = this.currentNumber;
			const delayLoop = (number > 0) ? this.delayLoop : 0;
			if (number >= this.totalNumber) {
				this.currentNumber = 0;
				number = 0;

				if (this.activeMiddleLoop) {
					this.setActiveFinishLoop();
				}

				if (this.activeLeftLoopEnd && !this.leftLoopFinished) {
					this.stopLeftReel();
					gsap.delayedCall(2.05, this.stopRightReel, [], this);
				}
			}

			if (!this.leftLoopFinished) {
				this.startDownNumerAnimation(number, this.speedLoop);
			}

			if (!this.activeRightLoopEnd) {
				this.startUpNumerAnimation(number, this.speedLoop);
			}

			gsap.delayedCall(delayLoop, this.loopAnimation, [], this);
			this.currentNumber = number + 1;
		},
		setActiveMiddleLoop() {
			if (this.activeMiddleLoop || this.activeLeftLoopEnd) {
				return;
			}

			this.timeoutMiddleLoop = setTimeout(() => {
				clearTimeout(this.timeoutMiddleLoop);
				this.timeoutMiddleLoop = null;

				this.activeMiddleLoop = true;
			}, 8000);
		},
		setActiveFinishLoop() {
			if (!this.activeMiddleLoop) {
				return;
			}

			this.activeMiddleLoop = false;

			this.timeoutFinishLoop = setTimeout(() => {
				clearTimeout(this.timeoutFinishLoop);
				this.timeoutFinishLoop = null;

				this.activeLeftLoopEnd = true;
			}, 1000);
		},
		startSecuenceResult() {
			const numbers = document.querySelectorAll('.numberResult');
			const numbersToScale = [];

			if (numbers?.length > 0) {
				numbers.forEach((number, index) => {
					if (index !== 2 && index !== 7) {
						this.fadeOutAnimation(number);
					} else {
						numbersToScale.push(number);
					}
				});
			}

			this.timeoutStartSecuence = setTimeout(() => {
				clearTimeout(this.timeoutStartSecuence);
				this.timeoutStartSecuence = null;

				numbersToScale.forEach((number) => {
					this.scaleResultAnimation(number);
				});

				this.showResult = true;
			}, 1000);
		},
		stopLeftReel() {
			if (this.leftLoopFinished) { return; }

			this.leftLoopFinished = true;

			const resultToLeftReel = Number(this.result[0]);
			const twoPreviouNumberLeft = (resultToLeftReel - 2 < 0) ? 0 : resultToLeftReel - 2;
			const previouNumberLeft = (resultToLeftReel - 1 < 0) ? 0 : resultToLeftReel - 1;
			const nextNumberLeft = (resultToLeftReel + 1 >= this.totalNumber) ? 0 : resultToLeftReel + 1;
			const twoNextNumberLeft = (resultToLeftReel + 2 >= this.totalNumber) ? 1 : resultToLeftReel + 2;

			const leftReelToShow = [...this.reelToShow];
			leftReelToShow[0].id = twoNextNumberLeft;
			leftReelToShow[1].id = nextNumberLeft;
			leftReelToShow[2].id = resultToLeftReel;
			leftReelToShow[3].id = previouNumberLeft;
			leftReelToShow[4].id = twoPreviouNumberLeft;

			let delay = 0.15;
			leftReelToShow.forEach((number, index) => {
				this.finishDownNumerAnimation(number.id, number.pos, delay, index);
				delay += 0.15;
			});
		},
		stopRightReel() {
			if (!this.activeLoop) { return; }

			this.activeLoop = false;
			this.activeRightLoopEnd = true;

			const resultToRightReel = Number(this.result[1]);
			const twoPreviousNumberRight = (resultToRightReel + 2 >= this.totalNumber) ? 1 : resultToRightReel + 2;
			const previouNumberRight = (resultToRightReel + 1 >= this.totalNumber) ? 0 : resultToRightReel + 1;
			const nextNumberRight = (resultToRightReel - 1 < 0) ? 0 : resultToRightReel - 1;
			const twoNextNumberRight = (resultToRightReel - 2 < 0) ? 0 : resultToRightReel - 2;

			const rightReelToShow = [...this.reelToShow].reverse();
			rightReelToShow[0].id = twoPreviousNumberRight;
			rightReelToShow[1].id = previouNumberRight;
			rightReelToShow[2].id = resultToRightReel;
			rightReelToShow[3].id = nextNumberRight;
			rightReelToShow[4].id = twoNextNumberRight;

			let delay = 0.1;
			rightReelToShow.forEach((number, index) => {
				this.finishUpNumerAnimation(number.id, number.pos, delay, index);
				delay += 0.15;
			});
		},
		setShowSupportUs() {
			setTimeout(() => {
				this.showSupportUs = true;
			}, 5000);
		},
		// ********** ANIMATIONS **********
		startDownNumerAnimation(numberId, speedLoop) {
			const id = this.totalNumber - 1 - numberId;
			const number = document.getElementById(`l-number-${id}`);

			if (number) {
				const callback = () => {
					gsap.killTweensOf(number);
					number.style.top = '-120%';
				};

				const animation =	gsap.to(number, speedLoop, {
					ease: Sine.easeInOut,
					top: '120%',
				});

				animation.eventCallback('onComplete', callback);
			}
		},
		startUpNumerAnimation(numberId, speedLoop) {
			const number = document.getElementById(`r-number-${numberId}`);

			if (number) {
				const callback = () => {
					gsap.killTweensOf(number);
					number.style.top = '120%';
				};

				const animation = gsap.to(number, speedLoop, {
					ease: Sine.easeInOut,
					top: '-120%',
				});

				animation.eventCallback('onComplete', callback);
			}
		},
		finishDownNumerAnimation(numberId, pos, delay, index) {
			const number = document.getElementById(`l-result-pos-${index}`);
			const pointer = document.getElementById('pointerLeft');

			if (number) {
				number.innerText = numberId;
				const callback = () => {
					this.stopSound?.play();
					this.spinSound.volume = 0.1;
					pointer?.classList.add('removeAnimation');
				};

				gsap.fromTo(number, this.speedLoop,
					{ top: '-50%' },
					{
						ease: Back.easeOut.config(1.2),
						delay,
						top: pos,
						onComplete: (index === 0) ? callback : null,
					});
			}
		},
		finishUpNumerAnimation(numberId, pos, delay, index) {
			const number = document.getElementById(`r-result-pos-${index}`);
			const pointer = document.getElementById('pointerRight');

			if (number) {
				const callback = () => {
					this.startSecuenceResult();
					pointer?.classList.add('removeAnimation');

					if (index === 2) {
						this.stopSound?.play();
						this.spinSound.pause();
					}
				};

				number.innerText = numberId;
				gsap.fromTo(number, this.speedLoop,
					{ top: '120%' },
					{
						ease: Back.easeOut.config(1.2),
						delay,
						top: pos,
						onComplete: callback,
					});
			}
		},
		scaleResultAnimation(number) {
			const callback = () => {
				if (this.winSound) {
					this.winSound.play();
				}
			};

			gsap.to(number, 1, {
				ease: Back.easeOut.config(1.5),
				delay: 2,
				top: '35%',
				scale: 1.1,
				color: '#0119CB',
				onStart: () => { this.preWinSound?.play(); },
				onComplete: callback,
			});
		},
		fadeOutAnimation(number) {
			gsap.to(number, 1, {
				delay: 2,
				ease: 'none',
				opacity: 0,
			});
		},
		visibleBackgroundReels(visible) {
			const reels = document.querySelectorAll('.reel');
			const pointers = document.querySelectorAll('.pointer');
			const background = (visible) ? 'rgba(0, 163, 224, 0.2)' : 'transparent';
			const opacity = (visible) ? 1 : 0;

			reels?.forEach((reel) => {
				// eslint-disable-next-line no-param-reassign
				reel.style.background = background;
			});

			pointers?.forEach((pointer) => {
				// eslint-disable-next-line no-param-reassign
				pointer.style.opacity = opacity;
			});
		},
		killAllAnimations() {
			const numbers = document.querySelectorAll('.numberReel');

			if (numbers?.length > 0) {
				numbers.forEach((number) => {
					gsap.killTweensOf(number);
					gsap.set(number, { clearProps: 'all' });
				});
			}
		},
	},
};

</script>
