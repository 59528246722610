/* eslint-disable no-plusplus */
export default class CommManager {
	constructor() {
		this.initialized = false;
		this.store = null;

		// Set values
		this.liveRaffleData = null;

		// Set parsed value of upcoming draws
		this.currentDraw = null;
		this.previousDraw = null;

		// Saves old draws
		this.oldDrawsRaffled = [];

		// Set listeners and timers
		this.listenerLiveRaffleData = null;
		// time to show the raffle after the draw. Expressed in seconds
		this.timeToShow = 30;
		// Additional time to the draw. Expressed in seconds
		this.offTimeRaffle = 120;
		this.timerLiveRaffle = 20000; // Expressed in miliseconds
		this.timerDefault = 4000; // Expressed in miliseconds

		// Data API Connection through static URL
		// this.urlServices = '/drawinformation/Facilito';
		// this.productiveMode = true;

		// Data API Connection through ENV
		// this.urlServices = process.env.VUE_APP_API_URL;

		// Test data API Connection through static URL
		this.urlServices = './response.xml';
		this.productiveMode = false;
	}

	initialize(store) {
		this.initialized = true;
		this.store = store;

		this.dispatchRequestLiveRaffle(true);
	}

	dispatchRequestLiveRaffle(retryMode) {
		this.listenerLiveRaffleData = setTimeout(() => {
			clearTimeout(this.listenerLiveRaffleData);

			this.getLiveRaffle()
				.then((returnedData) => {
					if (returnedData) {
						this.liveRaffleData = returnedData;
						this.parseLiveDraws(this.liveRaffleData);
					}
				});
		}, (!retryMode) ? this.timerLiveRaffle : this.timerDefault);
	}

	getLiveRaffle() {
		const urlBase = `${this.urlServices}`;
		const headers = new Headers({
			'Content-Type': 'text/xml',
		});
		const requestOptions = {
			method: 'GET',
			headers,
			redirect: 'follow',
			cache: 'no-store',
		};

		const returnedData = fetch(urlBase, requestOptions)
			.then((response) => response.text())
			.then((result) => {
				if (result) {
					const resultRed = result.replaceAll('lottery:', '');
					const parser = new DOMParser();
					const xml = parser.parseFromString(resultRed, 'text/xml');
					return xml;
				}

				// Return error
				console.error('LIVE >>> Error in data...');
				this.dispatchRequestLiveRaffle(false);
				return false;
			})
			.catch((error) => {
				console.error('Catch Error', error);
				this.dispatchRequestLiveRaffle(false);

				return false;
			});

		return returnedData;
	}

	parseLiveDraws(xmlDoc) {
		const parseDate = (timestamp) => {
			const timeParsed = new Date(Number(timestamp));
			timeParsed.setSeconds(timeParsed.getSeconds() + this.offTimeRaffle);
			const month = timeParsed.toLocaleString('es-ES', { month: 'long' });
			const hour12 = timeParsed.toLocaleString('es-ES', { hour: 'numeric', minute: 'numeric', hour12: true });
			const date = `${timeParsed.getDate()} de ${month}, ${timeParsed.getUTCFullYear()}`;
			const time = hour12.toLocaleUpperCase();

			return { date, time };
		};

		const response = {};
		const infoServer = xmlDoc.getElementsByTagName('server');
		const infoGame = xmlDoc.getElementsByTagName('game');
		const infoDraw = xmlDoc.getElementsByTagName('draw');

		if (infoServer.length) {
			const server = infoServer[0];
			const time = server?.getAttribute('time');
			response.server = {
				time,
			};
		} else {
			console.error('LIVE >>> Error in data...');
			this.dispatchRequestLiveRaffle(false);
			return;
		}

		if (infoGame.length) {
			const game = infoGame[0];
			const name = game?.getAttribute('gamename');
			const gameId = game?.getAttribute('gameid');
			const timestamp = game?.getAttribute('latestdrawenddate');
			const { date, time } = parseDate(timestamp);
			response.currentRaffleData = {
				date,
				time,
				gameId,
				name,
				timestamp,
			};
		}

		if (infoDraw.length) {
			const draw = infoDraw[0];
			const raffleId = draw?.getAttribute('drawid');
			const status = draw?.getAttribute('status');
			const raffleNumber = draw?.getAttribute('drawnumber');
			const jackpot = draw?.getAttribute('jackpot');
			const timestamp = draw?.getAttribute('drawdate');
			const result = draw?.getAttribute('drawresult')?.replace(/[^0-9]/g, '');
			const { date, time } = parseDate(timestamp);

			response.currentRaffleData.raffleId = raffleId;
			response.currentRaffleData.key = `${response.currentRaffleData.gameId}-${raffleId}`;
			response.preRaffleData = {
				date,
				time,
				raffleNumber,
			};
			response.raffleData = {
				date,
				time,
				jackpot,
				raffleId,
				raffleNumber,
				result,
				status,
				timestamp,
			};
		}

		// validate is a new raffle
		const raffleFiltered = this.oldDrawsRaffled.filter((elem) => elem === response.currentRaffleData.key);
		if (raffleFiltered.length) {
			console.info('this raffle was displayed!!!');
			this.dispatchRequestLiveRaffle(false);
			return;
		}

		const raffleDelta = ((response.server.time - response.raffleData.timestamp) / 60000);
		if (raffleDelta > this.timeToShow) {
			console.info(`This raffle was made more than ${raffleDelta} min ago!!!`);
			this.dispatchRequestLiveRaffle(false);
			return;
		}

		if (response.raffleData?.status !== 'REDEMPTION') {
			console.info('raffle status not allowed');
			this.dispatchRequestLiveRaffle(false);
			return;
		}

		// Set new raffle and save
		this.store?.commit('setStartRaffle', response);
		this.oldDrawsRaffled.push(response.currentRaffleData.key);
	}
}
